export const UserRole = {
  ADMIN: "admin",
  TA: "ta",
  STUDENT: "student",
};

export const downloadAndroidLink = "https://linq-home.oss-cn-hongkong.aliyuncs.com/app-release.apk";
export const downloadIosLink = "https://apps.apple.com/us/app/oxygen-mobile/id6739667958";

export const getEmailAndJWT = () => {
  const email = localStorage.getItem("email");
  const JWT = localStorage.getItem("JWT");
  return { email, JWT };
};

export function getRoleFromLocalStorage() {
  const role = localStorage.getItem("role");
  return role;
}

export const countryData = [
  { code: "AF", prefix: "93", name: "Afghanistan", flag: "🇦🇫" },
  { code: "AL", prefix: "355", name: "Albania", flag: "🇦🇱" },
  { code: "DZ", prefix: "213", name: "Algeria", flag: "🇩🇿" },
  { code: "AS", prefix: "1-684", name: "American Samoa", flag: "🇦🇸" },
  { code: "AD", prefix: "376", name: "Andorra", flag: "🇦🇩" },
  { code: "AO", prefix: "244", name: "Angola", flag: "🇦🇴" },
  { code: "AI", prefix: "1-264", name: "Anguilla", flag: "🇦🇮" },
  { code: "AG", prefix: "1-268", name: "Antigua and Barbuda", flag: "🇦🇬" },
  { code: "AR", prefix: "54", name: "Argentina", flag: "🇦🇷" },
  { code: "AM", prefix: "374", name: "Armenia", flag: "🇦🇲" },
  { code: "AW", prefix: "297", name: "Aruba", flag: "🇦🇼" },
  { code: "AU", prefix: "61", name: "Australia", flag: "🇦🇺" },
  { code: "AT", prefix: "43", name: "Austria", flag: "🇦🇹" },
  { code: "AZ", prefix: "994", name: "Azerbaijan", flag: "🇦🇿" },
  { code: "BS", prefix: "1-242", name: "Bahamas", flag: "🇧🇸" },
  { code: "BH", prefix: "973", name: "Bahrain", flag: "🇧🇭" },
  { code: "BD", prefix: "880", name: "Bangladesh", flag: "🇧🇩" },
  { code: "BB", prefix: "1-246", name: "Barbados", flag: "🇧🇧" },
  { code: "BY", prefix: "375", name: "Belarus", flag: "🇧🇾" },
  { code: "BE", prefix: "32", name: "Belgium", flag: "🇧🇪" },
  { code: "BZ", prefix: "501", name: "Belize", flag: "🇧🇿" },
  { code: "BJ", prefix: "229", name: "Benin", flag: "🇧🇯" },
  { code: "BM", prefix: "1-441", name: "Bermuda", flag: "🇧🇲" },
  { code: "BT", prefix: "975", name: "Bhutan", flag: "🇧🇹" },
  { code: "BO", prefix: "591", name: "Bolivia", flag: "🇧🇴" },
  { code: "BA", prefix: "387", name: "Bosnia and Herzegovina", flag: "🇧🇦" },
  { code: "BW", prefix: "267", name: "Botswana", flag: "🇧🇼" },
  { code: "BR", prefix: "55", name: "Brazil", flag: "🇧🇷" },
  {
    code: "IO",
    prefix: "246",
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
  },
  { code: "VG", prefix: "1-284", name: "British Virgin Islands", flag: "🇻🇬" },
  { code: "BN", prefix: "673", name: "Brunei", flag: "🇧🇳" },
  { code: "BG", prefix: "359", name: "Bulgaria", flag: "🇧🇬" },
  { code: "BF", prefix: "226", name: "Burkina Faso", flag: "🇧🇫" },
  { code: "BI", prefix: "257", name: "Burundi", flag: "🇧🇮" },
  { code: "CV", prefix: "238", name: "Cape Verde", flag: "🇨🇻" },
  { code: "KH", prefix: "855", name: "Cambodia", flag: "🇰🇭" },
  { code: "CM", prefix: "237", name: "Cameroon", flag: "🇨🇲" },
  { code: "CA", prefix: "1", name: "Canada", flag: "🇨🇦" },
  { code: "KY", prefix: "1-345", name: "Cayman Islands", flag: "🇰🇾" },
  { code: "CF", prefix: "236", name: "Central African Republic", flag: "🇨🇫" },
  { code: "TD", prefix: "235", name: "Chad", flag: "🇹🇩" },
  { code: "CL", prefix: "56", name: "Chile", flag: "🇨🇱" },
  { code: "CN", prefix: "86", name: "China", flag: "🇨🇳" },
  { code: "CO", prefix: "57", name: "Colombia", flag: "🇨🇴" },
  { code: "KM", prefix: "269", name: "Comoros", flag: "🇰🇲" },
  { code: "CK", prefix: "682", name: "Cook Islands", flag: "🇨🇰" },
  { code: "CR", prefix: "506", name: "Costa Rica", flag: "🇨🇷" },
  { code: "HR", prefix: "385", name: "Croatia", flag: "🇭🇷" },
  { code: "CU", prefix: "53", name: "Cuba", flag: "🇨🇺" },
  { code: "CW", prefix: "599", name: "Curaçao", flag: "🇨🇼" },
  { code: "CY", prefix: "357", name: "Cyprus", flag: "🇨🇾" },
  { code: "CZ", prefix: "420", name: "Czech Republic", flag: "🇨🇿" },
  {
    code: "CD",
    prefix: "243",
    name: "Democratic Republic of the Congo",
    flag: "🇨🇩",
  },
  { code: "DK", prefix: "45", name: "Denmark", flag: "🇩🇰" },
  { code: "DJ", prefix: "253", name: "Djibouti", flag: "🇩🇯" },
  { code: "DM", prefix: "1-767", name: "Dominica", flag: "🇩🇲" },
  { code: "DO", prefix: "1-809", name: "Dominican Republic", flag: "🇩🇴" },
  { code: "TL", prefix: "670", name: "East Timor", flag: "🇹🇱" },
  { code: "EC", prefix: "593", name: "Ecuador", flag: "🇪🇨" },
  { code: "EG", prefix: "20", name: "Egypt", flag: "🇪🇬" },
  { code: "SV", prefix: "503", name: "El Salvador", flag: "🇸🇻" },
  { code: "GQ", prefix: "240", name: "Equatorial Guinea", flag: "🇬🇶" },
  { code: "ER", prefix: "291", name: "Eritrea", flag: "🇪🇷" },
  { code: "EE", prefix: "372", name: "Estonia", flag: "🇪🇪" },
  { code: "SZ", prefix: "268", name: "Eswatini", flag: "🇸🇿" },
  { code: "ET", prefix: "251", name: "Ethiopia", flag: "🇪🇹" },
  { code: "FJ", prefix: "679", name: "Fiji", flag: "🇫🇯" },
  { code: "FI", prefix: "358", name: "Finland", flag: "🇫🇮" },
  { code: "FR", prefix: "33", name: "France", flag: "🇫🇷" },
  { code: "PF", prefix: "689", name: "French Polynesia", flag: "🇵🇫" },
  { code: "GA", prefix: "241", name: "Gabon", flag: "🇬🇦" },
  { code: "GM", prefix: "220", name: "Gambia", flag: "🇬🇲" },
  { code: "GE", prefix: "995", name: "Georgia", flag: "🇬🇪" },
  { code: "DE", prefix: "49", name: "Germany", flag: "🇩🇪" },
  { code: "GH", prefix: "233", name: "Ghana", flag: "🇬🇭" },
  { code: "GI", prefix: "350", name: "Gibraltar", flag: "🇬🇮" },
  { code: "GR", prefix: "30", name: "Greece", flag: "🇬🇷" },
  { code: "GL", prefix: "299", name: "Greenland", flag: "🇬🇱" },
  { code: "GD", prefix: "1-473", name: "Grenada", flag: "🇬🇩" },
  { code: "GU", prefix: "1-671", name: "Guam", flag: "🇬🇺" },
  { code: "GT", prefix: "502", name: "Guatemala", flag: "🇬🇹" },
  { code: "GN", prefix: "224", name: "Guinea", flag: "🇬🇳" },
  { code: "GW", prefix: "245", name: "Guinea-Bissau", flag: "🇬🇼" },
  { code: "GY", prefix: "592", name: "Guyana", flag: "🇬🇾" },
  { code: "HT", prefix: "509", name: "Haiti", flag: "🇭🇹" },
  { code: "HN", prefix: "504", name: "Honduras", flag: "🇭🇳" },
  { code: "HK", prefix: "852", name: "Hong Kong", flag: "🇭🇰" },
  { code: "HU", prefix: "36", name: "Hungary", flag: "🇭🇺" },
  { code: "IS", prefix: "354", name: "Iceland", flag: "🇮🇸" },
  { code: "IN", prefix: "91", name: "India", flag: "🇮🇳" },
  { code: "ID", prefix: "62", name: "Indonesia", flag: "🇮🇩" },
  { code: "IR", prefix: "98", name: "Iran", flag: "🇮🇷" },
  { code: "IQ", prefix: "964", name: "Iraq", flag: "🇮🇶" },
  { code: "IE", prefix: "353", name: "Ireland", flag: "🇮🇪" },
  { code: "IL", prefix: "972", name: "Israel", flag: "🇮🇱" },
  { code: "IT", prefix: "39", name: "Italy", flag: "🇮🇹" },
  { code: "CI", prefix: "225", name: "Ivory Coast", flag: "🇨🇮" },
  { code: "JM", prefix: "1-876", name: "Jamaica", flag: "🇯🇲" },
  { code: "JP", prefix: "81", name: "Japan", flag: "🇯🇵" },
  { code: "JO", prefix: "962", name: "Jordan", flag: "🇯🇴" },
  { code: "KZ", prefix: "7", name: "Kazakhstan", flag: "🇰🇿" },
  { code: "KE", prefix: "254", name: "Kenya", flag: "🇰🇪" },
  { code: "KI", prefix: "686", name: "Kiribati", flag: "🇰🇮" },
  { code: "XK", prefix: "383", name: "Kosovo", flag: "🇽🇰" },
  { code: "KW", prefix: "965", name: "Kuwait", flag: "🇰🇼" },
  { code: "KG", prefix: "996", name: "Kyrgyzstan", flag: "🇰🇬" },
  { code: "LA", prefix: "856", name: "Laos", flag: "🇱🇦" },
  { code: "LV", prefix: "371", name: "Latvia", flag: "🇱🇻" },
  { code: "LB", prefix: "961", name: "Lebanon", flag: "🇱🇧" },
  { code: "LS", prefix: "266", name: "Lesotho", flag: "🇱🇸" },
  { code: "LR", prefix: "231", name: "Liberia", flag: "🇱🇷" },
  { code: "LY", prefix: "218", name: "Libya", flag: "🇱🇾" },
  { code: "LI", prefix: "423", name: "Liechtenstein", flag: "🇱🇮" },
  { code: "LT", prefix: "370", name: "Lithuania", flag: "🇱🇹" },
  { code: "LU", prefix: "352", name: "Luxembourg", flag: "🇱🇺" },
  { code: "MO", prefix: "853", name: "Macau", flag: "🇲🇴" },
  { code: "MK", prefix: "389", name: "North Macedonia", flag: "🇲🇰" },
  { code: "MG", prefix: "261", name: "Madagascar", flag: "🇲🇬" },
  { code: "MW", prefix: "265", name: "Malawi", flag: "🇲🇼" },
  { code: "MY", prefix: "60", name: "Malaysia", flag: "🇲🇾" },
  { code: "MV", prefix: "960", name: "Maldives", flag: "🇲🇻" },
  { code: "ML", prefix: "223", name: "Mali", flag: "🇲🇱" },
  { code: "MT", prefix: "356", name: "Malta", flag: "🇲🇹" },
  { code: "MH", prefix: "692", name: "Marshall Islands", flag: "🇲🇭" },
  { code: "MR", prefix: "222", name: "Mauritania", flag: "🇲🇷" },
  { code: "MU", prefix: "230", name: "Mauritius", flag: "🇲🇺" },
  { code: "YT", prefix: "262", name: "Mayotte", flag: "🇾🇹" },
  { code: "MX", prefix: "52", name: "Mexico", flag: "🇲🇽" },
  { code: "FM", prefix: "691", name: "Micronesia", flag: "🇫🇲" },
  { code: "MD", prefix: "373", name: "Moldova", flag: "🇲🇩" },
  { code: "MC", prefix: "377", name: "Monaco", flag: "🇲🇨" },
  { code: "MN", prefix: "976", name: "Mongolia", flag: "🇲🇳" },
  { code: "ME", prefix: "382", name: "Montenegro", flag: "🇲🇪" },
  { code: "MS", prefix: "1-664", name: "Montserrat", flag: "🇲🇸" },
  { code: "MA", prefix: "212", name: "Morocco", flag: "🇲🇦" },
  { code: "MZ", prefix: "258", name: "Mozambique", flag: "🇲🇿" },
  { code: "MM", prefix: "95", name: "Myanmar", flag: "🇲🇲" },
  { code: "NA", prefix: "264", name: "Namibia", flag: "🇳🇦" },
  { code: "NR", prefix: "674", name: "Nauru", flag: "🇳🇷" },
  { code: "NP", prefix: "977", name: "Nepal", flag: "🇳🇵" },
  { code: "NL", prefix: "31", name: "Netherlands", flag: "🇳🇱" },
  { code: "AN", prefix: "599", name: "Netherlands Antilles", flag: "🇳🇱" },
  { code: "NC", prefix: "687", name: "New Caledonia", flag: "🇳🇨" },
  { code: "NZ", prefix: "64", name: "New Zealand", flag: "🇳🇿" },
  { code: "NI", prefix: "505", name: "Nicaragua", flag: "🇳🇮" },
  { code: "NE", prefix: "227", name: "Niger", flag: "🇳🇪" },
  { code: "NG", prefix: "234", name: "Nigeria", flag: "🇳🇬" },
  { code: "NU", prefix: "683", name: "Niue", flag: "🇳🇺" },
  { code: "NF", prefix: "672", name: "Norfolk Island", flag: "🇳🇫" },
  { code: "KP", prefix: "850", name: "North Korea", flag: "🇰🇵" },
  { code: "MP", prefix: "1-670", name: "Northern Mariana Islands", flag: "🇲🇵" },
  { code: "NO", prefix: "47", name: "Norway", flag: "🇳🇴" },
  { code: "OM", prefix: "968", name: "Oman", flag: "🇴🇲" },
  { code: "PK", prefix: "92", name: "Pakistan", flag: "🇵🇰" },
  { code: "PW", prefix: "680", name: "Palau", flag: "🇵🇼" },
  { code: "PS", prefix: "970", name: "Palestine", flag: "🇵🇸" },
  { code: "PA", prefix: "507", name: "Panama", flag: "🇵🇦" },
  { code: "PG", prefix: "675", name: "Papua New Guinea", flag: "🇵🇬" },
  { code: "PY", prefix: "595", name: "Paraguay", flag: "🇵🇾" },
  { code: "PE", prefix: "51", name: "Peru", flag: "🇵🇪" },
  { code: "PH", prefix: "63", name: "Philippines", flag: "🇵🇭" },
  { code: "PL", prefix: "48", name: "Poland", flag: "🇵🇱" },
  { code: "PT", prefix: "351", name: "Portugal", flag: "🇵🇹" },
  { code: "PR", prefix: "1-787", name: "Puerto Rico", flag: "🇵🇷" },
  { code: "QA", prefix: "974", name: "Qatar", flag: "🇶🇦" },
  { code: "CG", prefix: "242", name: "Republic of the Congo", flag: "🇨🇬" },
  { code: "RE", prefix: "262", name: "Réunion", flag: "🇷🇪" },
  { code: "RO", prefix: "40", name: "Romania", flag: "🇷🇴" },
  { code: "RU", prefix: "7", name: "Russia", flag: "🇷🇺" },
  { code: "RW", prefix: "250", name: "Rwanda", flag: "🇷🇼" },
  { code: "BL", prefix: "590", name: "Saint Barthélemy", flag: "🇧🇱" },
  { code: "SH", prefix: "290", name: "Saint Helena", flag: "🇸🇭" },
  { code: "KN", prefix: "1-869", name: "Saint Kitts and Nevis", flag: "🇰🇳" },
  { code: "LC", prefix: "1-758", name: "Saint Lucia", flag: "🇱🇨" },
  { code: "MF", prefix: "590", name: "Saint Martin", flag: "🇲🇫" },
  { code: "PM", prefix: "508", name: "Saint Pierre and Miquelon", flag: "🇵🇲" },
  {
    code: "VC",
    prefix: "1-784",
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
  },
  { code: "WS", prefix: "685", name: "Samoa", flag: "🇼🇸" },
  { code: "SM", prefix: "378", name: "San Marino", flag: "🇸🇲" },
  { code: "ST", prefix: "239", name: "São Tomé and Príncipe", flag: "🇸🇹" },
  { code: "SA", prefix: "966", name: "Saudi Arabia", flag: "🇸🇦" },
  { code: "SN", prefix: "221", name: "Senegal", flag: "🇸🇳" },
  { code: "RS", prefix: "381", name: "Serbia", flag: "🇷🇸" },
  { code: "SC", prefix: "248", name: "Seychelles", flag: "🇸🇨" },
  { code: "SL", prefix: "232", name: "Sierra Leone", flag: "🇸🇱" },
  { code: "SG", prefix: "65", name: "Singapore", flag: "🇸🇬" },
  { code: "SX", prefix: "1-721", name: "Sint Maarten", flag: "🇸🇽" },
  { code: "SK", prefix: "421", name: "Slovakia", flag: "🇸🇰" },
  { code: "SI", prefix: "386", name: "Slovenia", flag: "🇸🇮" },
  { code: "SB", prefix: "677", name: "Solomon Islands", flag: "🇸🇧" },
  { code: "SO", prefix: "252", name: "Somalia", flag: "🇸🇴" },
  { code: "ZA", prefix: "27", name: "South Africa", flag: "🇿🇦" },
  { code: "KR", prefix: "82", name: "South Korea", flag: "🇰🇷" },
  { code: "SS", prefix: "211", name: "South Sudan", flag: "🇸🇸" },
  { code: "ES", prefix: "34", name: "Spain", flag: "🇪🇸" },
  { code: "LK", prefix: "94", name: "Sri Lanka", flag: "🇱🇰" },
  { code: "SD", prefix: "249", name: "Sudan", flag: "🇸🇩" },
  { code: "SR", prefix: "597", name: "Suriname", flag: "🇸🇷" },
  { code: "SE", prefix: "46", name: "Sweden", flag: "🇸🇪" },
  { code: "CH", prefix: "41", name: "Switzerland", flag: "🇨🇭" },
  { code: "SY", prefix: "963", name: "Syria", flag: "🇸🇾" },
  { code: "TW", prefix: "886", name: "Taiwan", flag: "🇹🇼" },
  { code: "TJ", prefix: "992", name: "Tajikistan", flag: "🇹🇯" },
  { code: "TZ", prefix: "255", name: "Tanzania", flag: "🇹🇿" },
  { code: "TH", prefix: "66", name: "Thailand", flag: "🇹🇭" },
  { code: "TG", prefix: "228", name: "Togo", flag: "🇹🇬" },
  { code: "TK", prefix: "690", name: "Tokelau", flag: "🇹🇰" },
  { code: "TO", prefix: "676", name: "Tonga", flag: "🇹🇴" },
  { code: "TT", prefix: "1-868", name: "Trinidad and Tobago", flag: "🇹🇹" },
  { code: "TN", prefix: "216", name: "Tunisia", flag: "🇹🇳" },
  { code: "TR", prefix: "90", name: "Turkey", flag: "🇹🇷" },
  { code: "TM", prefix: "993", name: "Turkmenistan", flag: "🇹🇲" },
  { code: "TC", prefix: "1-649", name: "Turks and Caicos Islands", flag: "🇹🇨" },
  { code: "TV", prefix: "688", name: "Tuvalu", flag: "🇹🇻" },
  { code: "UG", prefix: "256", name: "Uganda", flag: "🇺🇬" },
  { code: "UA", prefix: "380", name: "Ukraine", flag: "🇺🇦" },
  { code: "AE", prefix: "971", name: "United Arab Emirates", flag: "🇦🇪" },
  { code: "GB", prefix: "44", name: "United Kingdom", flag: "🇬🇧" },
  { code: "US", prefix: "1", name: "United States", flag: "🇺🇸" },
  { code: "UY", prefix: "598", name: "Uruguay", flag: "🇺🇾" },
  { code: "UZ", prefix: "998", name: "Uzbekistan", flag: "🇺🇿" },
  { code: "VU", prefix: "678", name: "Vanuatu", flag: "🇻🇺" },
  { code: "VA", prefix: "379", name: "Vatican", flag: "🇻🇦" },
  { code: "VE", prefix: "58", name: "Venezuela", flag: "🇻🇪" },
  { code: "VN", prefix: "84", name: "Vietnam", flag: "🇻🇳" },
  { code: "VI", prefix: "1-340", name: "U.S. Virgin Islands", flag: "🇻🇮" },
  { code: "WF", prefix: "681", name: "Wallis and Futuna", flag: "🇼🇫" },
  { code: "EH", prefix: "212", name: "Western Sahara", flag: "🇪🇭" },
  { code: "YE", prefix: "967", name: "Yemen", flag: "🇾🇪" },
  { code: "ZM", prefix: "260", name: "Zambia", flag: "🇿🇲" },
  { code: "ZW", prefix: "263", name: "Zimbabwe", flag: "🇿🇼" },
];
