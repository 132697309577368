import { UPDATE_INSTITUTION_NAME } from "../constants";

const initialState = {
  institutionName: localStorage.getItem("institutionName"),
};

const institutionName = (state = initialState, action) => {
  //console.log(action.type, action.payload)
  switch (action.type) {
    case UPDATE_INSTITUTION_NAME:
      //console.log("Reducing updateInstitutionName with:", action.payload)
      return {
        ...state,
        institutionName: action.payload,
      };
    default:
      return state;
  }
};

export default institutionName;
