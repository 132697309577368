import * as React from "react"
import { PattenUniversityLogo } from "./logos/PattenUniversityLogo";
import { LincolnUniversityLogo } from "./logos/LincolnUniversityLogo";
import { PattenEliteHighschoolLogo } from "./logos/PattenEliteHighschoolLogo";
import { OxygenLogoSVG } from "./logos/OxygenLogoSVG";


const InsitutionLogoSVG = (props) => {
    // const institutionName = "Patten University"
    if (props.institutionName.institutionName === 'Patten University' || props.institutionName.institutionName === "Other") {
        return (<PattenUniversityLogo />);
    } else if (props.institutionName.institutionName === 'Lincoln University') {
        return (<LincolnUniversityLogo />);
    } else if (props.institutionName.institutionName === "Patten Elite High School") {
        return (<PattenEliteHighschoolLogo />)
    } else {
        return (<OxygenLogoSVG />)
    }
}

export default InsitutionLogoSVG;