import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { UserRole, getRoleFromLocalStorage } from "../constants";
import { routes, adminRoutes, taRoutes } from "../routes/index";
import OxygenAvatar from "../components/OxygenAvatar";
import InsitutionLogoSVG from "../components/InstitutionLogo";
import { checkReadViewMode } from "../pages/courses/utils";
import { OxygenLogoSVG } from "./logos/OxygenLogoSVG";


const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;
  const role = getRoleFromLocalStorage();
  const roleRoutes = {
    admin: adminRoutes,
    TA: taRoutes,
    default: routes,
  };
  const sidebarRoutes = roleRoutes[role] || roleRoutes.default;

  let _routes = {};

  sidebarRoutes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    // const isOpen = route.open;
    const isOpen = true;
    const isHome = route.containsHome && pathName === "/";

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = ({ location, sidebar, layout, institutionName }) => {
  const role = getRoleFromLocalStorage();
  const roleRoutes = {
    admin: adminRoutes,
    TA: taRoutes,
    default: routes,
  };
  const sidebarRoutes = roleRoutes[role] || roleRoutes.default;
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));
  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const readView = checkReadViewMode(document.location.search);

  return (
    <nav
      className={
        "sidebar" +
        (!sidebar.isOpen ? " toggled" : "") +
        (sidebar.isSticky ? " sidebar-sticky" : "")
      }
      style={readView ? { display: "none" } : undefined}
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <span className="sidebar-insitution-logo">
            <InsitutionLogoSVG institutionName={institutionName} />
          </span>
          <ul className="sidebar-nav">
            {sidebarRoutes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>{" "}
          <div
            className={
              role === UserRole.ADMIN || role === UserRole.TA
                ? "sidebar-bottom-admin" + (!sidebar.isOpen ? " toggled" : "")
                : "sidebar-bottom-student" + (!sidebar.isOpen ? " toggled" : "")
            }
          >
            <div className="media mb-5">
              <OxygenAvatar marginLeft={"10px"} username={layout.user.email} />
              <div className="media-body" style={{ paddingLeft: "0.75rem" }}>
                <h5 className="mb-1">{layout.user.email}</h5>
                <div>
                  <FontAwesomeIcon icon={faCircle} className="text-success" />{" "}
                  Online
                </div>
              </div>
            </div>
            {role === UserRole.ADMIN || role === UserRole.TA ? (
              <>
                <div style={{ color: "#A5A5A5", fontWeight: "bold" }}>
                  Oxygen Admission Admin
                </div>
                {/* <div
                  className="mt-2"
                  style={{ color: "white", fontSize: "12px" }}
                >
                  {layout.user.org}-{role.toUpperCase()}
                </div> */}
              </>
            ) : null}
            <div
              className={"sidebar-bottom" + (!sidebar.isOpen ? " toggled" : "")}
            >
              <div className="d-flex justify-content-center align-content-center">
                {" "}
                <a className="sidebar-brand" href={"/pages/courses"}>
                  <OxygenLogoSVG />
                  <span
                    className="align-middle"
                    style={{ paddingLeft: "0.5em" }}
                  >
                    Oxygen
                  </span>
                  <span
                    className="align-middle"
                    style={{ fontSize: "0.7em", paddingLeft: "0.5em" }}
                  >
                    v18.2.1
                  </span>
                </a>
              </div>

              <a className="sidebar-brand" href={"https://linq.ai/"}>
                <span
                  className="d-flex justify-content-center align-content-center"
                  style={{ fontSize: "0.7rem" }}
                >
                  Powered by LINQ.AI
                </span>
              </a>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
    institutionName: store.institutionName,
  }))(Sidebar)
);
