import React from "react";


export function OxygenLogoSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x={0}
      y={0}
      style={{
        enableBackground: "new 0 0 3000 3000",
      }}
      viewBox="0 0 3000 3000"
      {...props}
    >
      <style>{".st0{fill:#fff}"}</style>
      <path
        d="M2678 1502.7v179.5h-166.8V1497l-.1-6.6-.2-13.2c-.2-8.8-.6-17.6-.9-26.4-3.3-70.4-14.1-140.8-32.3-209.3-18.1-68.5-43.4-135.1-75.4-198.2-31.9-63.2-70.7-122.8-115-177.7-88.6-110.1-200.4-201-326.3-265.7-63-32.3-129.4-57.9-197.9-76.3-68.4-18.4-138.7-29.5-209.1-33.3-35.2-1.9-70.4-1.9-105.6-.1-35.2 1.8-70.4 5.4-105.4 10.9-69.9 10.9-138.8 29.3-204.9 54.6s-129.6 57.6-188.8 96.2C890.1 690.4 834.9 735.1 785 785c-49.9 50-94.6 105.1-133.1 164.3-38.6 59.2-70.9 122.6-96.2 188.8-25.4 66.1-43.7 135-54.6 204.9-5.5 34.9-9.1 70.1-10.9 105.3-1.8 35.2-1.7 70.4.1 105.6 3.7 70.4 14.8 140.8 33.3 209.2 18.4 68.4 44 134.9 76.3 197.9 64.7 125.9 155.6 237.8 265.7 326.3 55 44.3 114.6 83.1 177.7 115 63.1 32 129.7 57.3 198.2 75.4 68.5 18.1 138.9 28.9 209.3 32.2 8.8.3 17.6.7 26.4.9l13.2.2 6.6.1h185.2V2678h-185.7l-7.7-.1-15.3-.2c-10.2-.2-20.5-.7-30.7-1.1-81.9-3.9-164-16.5-243.8-37.6-79.9-21.1-157.5-50.7-231.1-87.9s-143-82.3-207-133.9c-128.1-103.1-234-233.3-309.4-380-37.6-73.4-67.5-150.9-89-230.7-21.5-79.7-34.4-161.7-38.8-243.7-2.2-41-2.2-81.8-.2-122.8 2.1-41 6.3-82 12.7-122.7 12.7-81.5 34.1-161.8 63.7-238.9 29.5-77.1 67.2-151 112.1-220 44.9-69 96.9-133.2 155-191.4 58.2-58.1 122.4-110.1 191.4-155 69-44.9 142.9-82.6 220-112.1 77.1-29.6 157.4-51 238.9-63.7 40.7-6.4 81.7-10.6 122.7-12.7 41-2.1 81.8-2 122.8.2 81.9 4.3 163.9 17.3 243.7 38.8 79.8 21.5 157.3 51.4 230.7 89 146.8 75.4 276.9 181.3 380 309.4 51.6 64 96.7 133.4 133.9 207 37.3 73.6 66.8 151.2 87.9 231.1 21.1 79.8 33.7 161.9 37.6 243.8.4 10.2.9 20.5 1.1 30.7l.2 15.3.1 7.7v6.2z"
        className="st0"
      />
      <path
        d="M2346.9 1500.2v182h-166.8V1498l-.1-4.5-.1-8.9c-.1-6-.4-11.9-.6-17.9-2.3-47.6-9.5-94.8-21.7-140.7-12.1-45.9-29.1-90.6-50.6-133-1.3-2.7-2.7-5.3-4.1-7.9l-4.2-7.9c-2.8-5.2-5.8-10.4-8.7-15.6-3-5.1-6-10.2-9.1-15.3l-4.7-7.6c-1.6-2.5-3.1-5.1-4.8-7.5-12.9-20-26.9-39.2-41.8-57.8-59.6-74.2-135.2-135.5-219.7-179-42.3-21.7-86.9-38.8-132.7-51.2-45.9-12.3-93.1-19.8-140.6-22.4-47.6-2.5-95.5-.2-142.4 7.2-47 7.3-93.1 19.7-137.5 36.7-88.8 33.9-170.2 87.1-237.5 154.3-67.2 67.4-120.5 148.7-154.3 237.5-17 44.4-29.3 90.5-36.7 137.5-7.4 47-9.8 94.8-7.2 142.4 2.5 47.6 10 94.7 22.4 140.6 12.3 45.9 29.5 90.4 51.2 132.8 43.5 84.5 104.8 160.1 179 219.7 18.5 14.9 37.8 28.9 57.8 41.8 2.5 1.7 5 3.2 7.5 4.8l7.6 4.7c5.1 3.1 10.2 6.1 15.3 9.1l15.6 8.7 7.9 4.2c2.6 1.4 5.2 2.8 7.9 4.1 42.4 21.5 87 38.5 133 50.6 45.9 12.2 93.2 19.4 140.7 21.7 6 .2 11.9.5 17.9.6l8.9.1 4.5.1h184.3v166.8h-184.7l-5.5-.1-11.1-.1c-7.4-.2-14.8-.5-22.2-.8-59.1-2.8-118-11.9-175.3-27-57.3-15.1-113-36.3-165.9-63.1-52.9-26.7-102.8-59.2-148.9-96.3-92.3-74.2-168.5-168-222.7-273.4-27-52.7-48.5-108.3-63.9-165.6-15.4-57.2-24.7-116.1-27.9-175.2-3.1-59-.2-118.4 9-176.9s24.5-116.1 45.7-171.5c42.3-110.8 108.5-212 192.1-295.8 83.7-83.6 185-149.9 295.8-192.1 55.3-21.2 112.9-36.6 171.5-45.7 58.5-9.2 117.9-12.1 176.9-9 59.1 3.1 117.9 12.4 175.2 27.9 57.2 15.4 112.8 36.8 165.6 63.9 105.4 54.1 199.2 130.4 273.4 222.7 37.1 46.1 69.6 96 96.3 148.9 26.8 52.9 48 108.6 63.1 165.9 15.2 57.3 24.2 116.2 27 175.3.3 7.4.6 14.8.8 22.2l.1 11.1.1 5.5-.1 2.6z"
        className="st0"
      />
      <path
        d="M2015.9 1500.2v182H1849v-179.5l-.1-10.8c-.1-3.1-.3-6.2-.3-9.3-1.1-24.7-4.9-48.8-11.1-72.2-6.2-23.4-14.8-46.1-25.8-67.8-.6-1.4-1.4-2.7-2.1-4l-2.1-4c-1.5-2.7-3-5.3-4.4-8-1.6-2.6-3.1-5.2-4.7-7.9l-2.4-3.9-2.4-3.9c-6.6-10.2-13.8-20.2-21.5-29.7-30.7-38.3-70-70-113.1-92.2a345.7 345.7 0 0 0-67.7-26.1c-23.4-6.3-47.4-10.1-72.1-11.5-12.3-.7-25-.7-37.3-.1-12.3.6-24.5 1.9-36.6 3.8-24 3.8-47.4 10-70 18.7-45.2 17.2-87.1 44.6-121.9 79.3-34.7 34.9-62.1 76.7-79.3 121.9-8.7 22.6-14.9 46-18.7 70-1.9 12-3.2 24.2-3.8 36.6-.6 12.3-.6 25 .1 37.3 1.3 24.7 5.2 48.7 11.4 72.1 6.3 23.3 15 46 26.1 67.7 22.2 43.2 53.9 82.4 92.2 113.1 9.6 7.7 19.5 14.9 29.7 21.6 1.3.9 2.6 1.6 3.9 2.4l3.9 2.4c2.6 1.6 5.3 3.1 7.9 4.7 2.7 1.4 5.3 3 8 4.4l4 2.1c1.3.7 2.7 1.5 4 2.1 21.7 11 44.4 19.6 67.8 25.8 23.4 6.2 47.5 9.9 72.2 11.1 3.1.1 6.2.3 9.3.3l10.8.1h179.5v166.8h-183.8l-3.4-.1-6.8-.1c-4.5-.1-9.1-.4-13.6-.5-36.2-1.7-72-7.2-106.7-16.4s-68.5-22-100.6-38.3c-2-1-4-2.1-6-3.1l-6-3.1-11.8-6.6-11.6-6.9-5.7-3.6c-1.9-1.2-3.9-2.3-5.7-3.6-15.1-9.8-29.8-20.4-43.9-31.8-56.4-45.3-103-102.8-135.9-166.8-16.4-32.1-29.4-65.8-38.7-100.5-9.3-34.7-15-70.4-16.9-106.6-1-18.1-1-36.4-.1-54.5s2.8-36.1 5.6-53.9c5.6-35.6 14.9-70.5 27.7-104 25.6-67.2 66-129 117.1-180.2 51.2-51.1 113-91.5 180.2-117.1 33.6-12.9 68.4-22.2 104-27.7 17.8-2.8 35.8-4.7 53.9-5.6 18.1-.9 36.4-.9 54.5.1 36.2 1.9 71.9 7.6 106.6 16.9 34.7 9.3 68.4 22.3 100.5 38.7 64 32.9 121.5 79.5 166.8 135.9 11.3 14.1 22 28.7 31.8 43.9 1.3 1.9 2.4 3.8 3.6 5.7l3.6 5.7 6.9 11.6 6.6 11.8 3.1 6c1 2 2.1 4 3.1 6 16.3 32.1 29.1 65.9 38.3 100.6 9.2 34.7 14.7 70.5 16.4 106.7.1 4.5.4 9.1.5 13.6l.1 6.8.1 3.4-.3 2.2z"
        className="st0"
      />
      <path
        d="M2346.9 1849h-166.8v.5h-164.2v-.5H1849v829h166.8v-330.6H2180V2678h166.8v-330.6h.5v-170.8h-.5v-161.2h.5v-166h-.5v-.4zm-166.8 327.7h-164.2v-161.2h164.2v161.2zM2511.2 1849H2678v829h-166.8z"
        className="st0"
      />
    </svg>
  );
}